(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldDate", function (controllerLinker, utilities, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			transclude: true,
			controller: function ($scope, $element) {
				$scope.uuid = utilities.getUUID();
				
				$scope.ui = {
					open: false
				};

				if ($scope.fieldModel) {
					$scope.fieldModel = utilities.handleServerDate($scope.fieldModel).local().toDate();
				}

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};

				$scope.reference = function () {
					return $scope.fieldLabel;
				};

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";

					var value = $scope.fieldModel;
					var string = $element.find("input").val();

					if ($scope.fieldRequired || $scope.typeViewFieldRequired) {
						if (value === undefined || !value) {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					}

					if (value === undefined && string) {
						var format = "dd-MMMM-YYYY";

						var isValid = moment(string, format).isValid();

						if (isValid === false) {
							$scope.validation.passed = false;
							$scope.validation.message = "".concat("Format: ", format);
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				$scope.$watch("fieldModel", function (value) {
					if ($scope.validation.active) {
						$scope.validate();
					}
				});
				
				$scope.onDatepickerUpdate = function (date) {
					$scope.fieldModel = date;
				};
				$scope.onDateSelection = function () {
					$scope.ui.open = false;
				};

				$scope.$watch("fieldDurationOptions", function () {
					if ($scope.fieldDurationOptions && $scope.fieldDurationOptions.length) {
						if ($scope.fieldAutoSelect) {
							$scope.fieldDropDownModel = $scope.fieldOptions[0];
						} else {
							$scope.fieldDropDownModel = undefined;
						}
					} else {
						$scope.fieldDropDownModel = undefined;
					}
				});

				$scope.$watch("fieldDropDownModel", function () {
					if ($scope.fieldDropDownModel) {
						var createdDate = moment($scope.fieldSelectedStartData).utc().format();
						var expirationDate = moment(createdDate).add($scope.fieldDropDownModel * 7, "d");
						$scope.fieldModel = new Date(expirationDate);
					}
				});

				$scope.$watch("fieldSelectedStartData", function () {
					if ($scope.fieldDropDownModel) {
						var createdDate = moment($scope.fieldSelectedStartData).utc().format();
						var expirationDate = moment(createdDate).add($scope.fieldDropDownModel * 7, "d");
						$scope.fieldModel = new Date(expirationDate);
					}
				});

				$element.find("input").onchange = function () {
					if ($scope.validation.active) {
						$scope.validate();
					}
				};

				$scope.onChanges = function () {
					if ($scope.onFieldChange) {
						$scope.onFieldChange();
					}
				};

				$scope.datepickerOptions = { 
					minDate: $scope.fieldMinimumDate, 
					maxDate: $scope.fieldMaximumDate, 
					showWeeks: false, 
					startingDay: 1 
				};

				$scope.onFocus = () => {
					$scope.ui.open = true;
					setTimeout(() => {
						$element[0].querySelector("date-picker-localisation-wrapper").scrollIntoView({ block: "nearest" });
					});
				};

				$scope.onBlur = ($event) => {
					var clickDatepickerTitle = $event.target.className.includes("uib-title") || $event.target.className.includes("uib-monthpicker") || $event.target.className.includes("uib-yearpicker");
					var closeDatepicker = !$event.relatedTarget || $event.relatedTarget.className.includes("field-date-button") || ($event.relatedTarget.tagName === "INPUT") || ($event.relatedTarget.tagName === "SELECT") || $event.relatedTarget.className.includes("modal") || $event.relatedTarget.className.includes("canopy-core-dropdown");
						
					if (closeDatepicker && !clickDatepickerTitle && $scope.ui.open) {
						$scope.ui.open = false;
					}
				};
				
				$scope.$watch("fieldMinimumDate", function (minimumDate) {
					$scope.datepickerOptions.minDate = minimumDate;
				});
				$scope.$watch("fieldMaximumDate", function (maximumDate) {
					$scope.datepickerOptions.maxDate = maximumDate;
				});
				
				if ($scope.onFieldModelChange) {
					$scope.$watch("fieldModel", (value, previous) => {
						if (value && value !== previous) {
							$scope.onFieldModelChange(value);
						}
					});
				}
			},
			scope: {
				fieldModel: "=",
				fieldLabel: "@",
				fieldDescription: "@",
				fieldRequired: "=",
				fieldPlaceholder: "@",
				fieldDisabled: "=",
				fieldMinimumDate: "=?",
				fieldMaximumDate: "=?",
				fieldDateController: "=",
				fieldDurationOptions: "=",
				fieldAutoSelect: "=",
				fieldSelectedStartData: "=",
				fieldDisplayFormat: "@",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				onValidationFailed: "&?",
				onFieldChange: "&?",
				onFieldModelChange: "&?",
				typeViewFieldDisabled: "=",
				typeViewFieldHidden: "=",
				typeViewFieldRequired: "=",
				typeViewFieldNulled: "=",
				typeViewMessage: "="
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-date.template.html"
		};
	});
})(canopyCore);
