"use strict";

canopyCore.factory("utilities", ["$timeout", function ($timeout) {
	return {
		debounce: function (ms, func) {
			var timer;
			return function (event) {
				if (timer) clearTimeout(timer);
				timer = setTimeout(func, ms ? ms : 250, event);
			};
		},
		StateTracker: function (states) {
			var _states = states;
			var _state = states.length ? states[0] : undefined;

			return {
				setState: function (state) {
					if (typeof (state) === "string") {
						for (var i = 0; i !== _states.length; ++i) {
							if (_states[i].name && _states[i].name === state) {
								_state = states[i];
							}
						}
					}
					else {
						if (_states.indexOf(state) >= 0) {
							_state = state;
						}
					}
				},
				getState: function () {
					return _state;
				},
				getStates: function () {
					return _states;
				}
			};
		},
		SelectionTracker: function () {
			var _selected = [];

			var findSelectionById = function (id) {
				for (var i = 0; i !== _selected.length; ++i) {
					if (_selected[i].id === id) {
						return i;
					}
				}

				return -1;
			};

			return {
				isSelected: function (object) {
					if (!object.id) {
						// Object doesn't have an id, can't track.
						throw "No object ID";
					}

					return findSelectionById(object.id) >= 0;
				},
				toggleSelection: function (object) {
					if (!object.id) {
						// Object doesn't have an id, can't track.
						throw "No object ID";
					}

					var index = findSelectionById(object.id);

					if (index >= 0) {
						_selected.splice(index, 1);
					}
					else {
						_selected.push(object);
					}
				},
				selection: _selected
			};
		},
		Poller: function (promise) {
			let _promise = null;
			let _interval = 1000;
		
			let _count = 0;
			let _limit = 5;
		
			const init = () => {
				_promise = $timeout(() => {
					if (_promise) {
						$timeout.cancel(_promise);
					}
				
					if (promise) {
						promise().then(persist => {
							_count = _count + 1;
							_interval = _interval * 2;
				
							if (persist && _count <= _limit) {
								init();
							}
						});
					}
				
				}, _interval);
			};
		
			init();
		},
		browserCheck: {
			isMobile: {
				Android: function () {
					return navigator.userAgent.match(/Android/i);
				},
				BlackBerry: function () {
					return navigator.userAgent.match(/BlackBerry/i);
				},
				iOS: function () {
					return navigator.userAgent.match(/iPhone|iPad|iPod/i);
				},
				Opera: function () {
					return navigator.userAgent.match(/Opera Mini/i);
				},
				Windows: function () {
					return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
				},
				any: function () {
					return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
				}
			}
		},
		date: function (value) {
			var _wrapped = moment();

			if (value) {
				if (typeof value === "string" || typeof value === "object") {
					_wrapped = moment(value);
				}
				else {
					if (parseInt(value)) {
						_wrapped = moment.unix(value);
					}
				}
			}

			return {
				offset: function (offset) {
					if (offset) {
						if (offset.years) {
							_wrapped.add(offset.years, "years");
						}
						if (offset.months) {
							_wrapped.add(offset.months, "months");
						}
						if (offset.days) {
							_wrapped.add(offset.days, "days");
						}
						if (offset.hours) {
							_wrapped.add(offset.hours, "hours");
						}
						if (offset.minutes) {
							_wrapped.add(offset.minutes, "minutes");
						}
						if (offset.seconds) {
							_wrapped.add(offset.seconds, "seconds");
						}
					}

					return this;
				},
				startOf: function (unit) {
					_wrapped.startOf(unit);

					return this;
				},
				endOf: function (unit) {
					_wrapped.endOf(unit);

					return this;
				},
				toString: function () {
					return _wrapped.toString();
				},
				toISOString: function () {
					return _wrapped.toISOString();
				},
				toDate: function () {
					return _wrapped.toDate();
				},
				toUnix: function () {
					return _wrapped.unix();
				},
				difference: function (date) {
					date = moment(date.toDate ? date.toDate() : date);

					return {
						years: _wrapped.diff(date, "years"),
						months: _wrapped.diff(date, "months"),
						days: _wrapped.diff(date, "days"),
						hours: _wrapped.diff(date, "hours"),
						minutes: _wrapped.diff(date, "minutes"),
						seconds: _wrapped.diff(date, "seconds")
					};
				}
			};
		},
		test: function () {
			var local = this;

			var testDates = function () {
				var testValue = "Fri Jan 02 2015 03:04:05 GMT+0000 (GMT)";
				var testOffset = {
					years: -1,
					months: 2,
					days: -3,
					hours: 4,
					minutes: -5,
					seconds: 6
				};

				var testDate = local.date(testValue);
				var testDateOffset = local.date(testValue).offset(testOffset);
				var testDifferenceNone = testDate.difference(testDate);
				var testDifferenceOffset = testDate.difference(testDateOffset);

				return testDate.toString() === "Fri Jan 02 2015 03:04:05 GMT+0000" &&
					testDateOffset.toString() === "Thu Feb 27 2014 06:59:11 GMT+0000" &&
					testDifferenceNone.years === 0 &&
					testDifferenceNone.months === 0 &&
					testDifferenceNone.days === 0 &&
					testDifferenceNone.hours === 0 &&
					testDifferenceNone.minutes === 0 &&
					testDifferenceNone.seconds === 0 &&
					testDifferenceOffset.years === 0 &&
					testDifferenceOffset.months === 10 &&
					testDifferenceOffset.days === 308 &&
					testDifferenceOffset.hours === 7412 &&
					testDifferenceOffset.minutes === 444724 &&
					testDifferenceOffset.seconds === 26683494;
			};

			var passed = testDates();

			if (passed) {
				// console.log("Test passed");
			}
			else {
				// console.log("Test failed");
			}
		},
		// Check date strings and convert to avoid Safari errors:
		handleServerDate: function (date) {
			if (typeof date === "string" && isNaN(new Date(date).getTime())) {
				date = date.replace(/-/g, "/");
			}
			return moment(date);
		},
		isInArray: function (needle, array) {
			return (array.indexOf(needle) > -1);
		},
		/**
		* Get Property value by traversing an Object by supplied string
		* @param {object} May have nested properties to traverse
		* @param {string} A path supplied in snake-case to find on supplied object if possible eg. "prop1.nestedProp.my-value"
		* @returns {string} Property value
		*/
		getPropVal: function (obj, key) {
			return key.split(".").reduce(function (o, x) {
				return (typeof o === "undefined" || o === null) ? o : o[x];
			}, obj);
		},
		isEmpty: function (obj) {
			for (var key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					return false;
				}
			}
			return true;
		},
		/**
			* Get collection from comma separated list in a string.
			* @param {string} filters Comma separated list of filter names,eg."TypeFilter, PeriodFilter"
			* @param {boolean} toLowerCase Convert collection to all lower case?
			* @returns {array}
			*/
		getCollectionFromString: function (string, toLowerCase) {
			if (toLowerCase === true) {
				return string.toLowerCase().replace(/ /g, "").split(",");
			}

			return string.replace(/ /g, "").split(",");
		},
		/**
		 * Recursively merge 2 objects without override.
		 * All properties of the targetObject are preserved and extended with extra properties from sourceObject.
		 * This is not deep merge.
		 * @param {Object} targetObject The object to be extended.
		 * @param {Object} sourceObject The object providing additional params for targetObject.
		 * @returns {Object} New extended object.
		 */
		extendObjects: function (targetObject, sourceObject) {
			if (!targetObject) {
				return sourceObject || {};
			}
			if (!sourceObject) {
				return targetObject || {};
			}

			var merged = angular.copy(targetObject);
			for (var attrname in sourceObject) {
				if (merged[attrname] === undefined) {
					merged[attrname] = sourceObject[attrname];
				}
			}

			return merged;
		},
		/**
		 * Calculate difference between two dates.
		 * @param {string} endDate The end date.
		 * @param {string} startDate The start date.
		 */
		calculateDiffInDays: function (endDate, startDate) {
			return this.handleServerDate(endDate).startOf("day").diff(this.handleServerDate(startDate).startOf("day"), "days");
		},
		// Return a new random field UUID:
		getUUID: function () {
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		},
		/**
		 * Sorts an array of objects according to supplied prop
		 * @param {string} prop object prop to sort by
		 */
		sortBy: function (prop) {
			return function (a, b) {
				if (a[prop] < b[prop]) { return -1; }
				if (a[prop] > b[prop]) { return 1; }
				return 0;
			};
		},
		/**
		 * isNodeParentVerticalOrSelf returns the node (self) or any one of its parent nodes that have node.metadata.presentation === "vertical"
		 * 
		 * @param {Object} node The menu node from the canopySession node collection 
		 * @returns {Object} node The menu node, possibly itself, with node.metadata.presentation === "vertical"
		 */
		isNodeParentVerticalOrSelf: function (node) {
			var isVertical = function (node) {
				if (node && node.metadata && node.metadata.presentation === "vertical") {
					return node;
				} else {
					if (node.parent) {
						return isVertical(node.parent);
					}
				}

				return undefined;
			};

			return isVertical(node);
		},
		generateCssNameFromString: function (str) {
			return str ? str.replace(/[^A-Z0-9]/ig, "").toLowerCase() : undefined;
		},
		isValidJSONString: function (str) {
			try {
				JSON.parse(str);
			} catch (e) {
				return false;
			}
			return true;
		},
		flattenTaxonomy: (taxonomy, copyGenusInfoToKeywords) => {
			let flattenedTaxonomy = taxonomy.reduce((keywords, genus) => {
				genus.keywords.forEach(function (keyword) {
					if (copyGenusInfoToKeywords) {
						keyword["genus-id"] = genus.id;
						keyword["genus-name"] = genus.name;
						keyword["genus-localisation"] = genus.localisation;
					}
				});
				return keywords.concat(genus.keywords);
			}, []);

			return flattenedTaxonomy;
		},

		flattenTypes: function (types) {
			const flatten = (types) => {
				var flattened = [];

				types.forEach(function (type) {
					if (type.id) {
						flattened.push(type);
					}

					if (type.subtypes) {
						flattened = flattened.concat(flatten(type.subtypes));
					}
				});

				return flattened;
			};

			return flatten(types);
		},

		flattenCollection: function (collection, property) {
			collection = collection.filter(function (element) {
				return element[property] !== undefined;
			});

			collection = collection.map(function (element) {
				return element[property];
			});

			return collection;
		},
		truncateDecimals: function (numberAsString, decimals = 2) {
			var decPosition = numberAsString.indexOf("."),
				substrLength = decPosition === -1 ? numberAsString.length : 1 + decPosition + decimals,
				trimmedResult = numberAsString.substr(0, substrLength),
				finalResult = isNaN(trimmedResult) ? "0" : trimmedResult;

			return finalResult;
		},
		isValidUrl: function (urlString) {
			let url;
			try {
				url = new URL(urlString);
			}
			catch (e) {
				return false;
			}

			const protocolOk = (url.protocol === "http:" || url.protocol === "https:");
			const forwardSlashesOk = (urlString.indexOf("http://") !== -1 || urlString.indexOf("https://") !== -1);
			const fullStopsOk = (url.host.lastIndexOf(".") !== -1 && url.host.length - url.host.lastIndexOf(".") > 2);

			return protocolOk && forwardSlashesOk && fullStopsOk;
		},
		getDateOnly: (lumaDateTimeString) => {
			return lumaDateTimeString.slice(0, lumaDateTimeString.indexOf(" "));
		}
	};
}]);
